/* You can add global styles to this file, and also import other style files */

// Icons
@import url("https://i.icomoon.io/public/5287269068/ngx-feng/style.css");

// Fonts
@font-face {
  font-family: D-DIN;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/d-din/D-DIN.otf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: D-DIN-bold;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/d-din/D-DIN-Bold.otf") format("truetype");
  font-style: bold;
  font-weight: 900;
}

@font-face {
  font-family: D-DIN-italic;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/d-din/D-DIN-Italic.otf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
    font-family: Montserrat;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
    font-family: Montserrat-bold;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    font-style: bold;
    font-weight: 900;
}
@font-face {
    font-family:Montserrat-SemiBold;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 700;
}

//USBlaak

@font-face {
  font-family: UsBlaakMedium;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/US-Blaak/US+Blaak+Black.otf") format("truetype");
}

// Integral CF
@font-face {
  font-family: IntegralCF;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Integral-font/IntegralCF-Regular.otf") format("truetype");
}
@font-face {
  font-family: IntegralCF-Bold;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Integral-font/IntegralCF-Bold.otf") format("truetype");
  font-style: bold;
  font-weight: 900;
}
@font-face {
  font-family:IntegralCF-SemiBold;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Integral-font/IntegralCF-Medium.otf") format("truetype");
  font-weight: 700;
}

// Adineue PRO
@font-face {
    font-family: AdineuePro;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/adineue+PRO/adineue+PRO.ttf") format("truetype");
}
@font-face {
    font-family: AdineuePro-Bold;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/adineue+PRO/adineue+PRO+Bold.ttf") format("truetype");
    font-style: bold;
    font-weight: 900;
}
@font-face {
  font-family: GillSans;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans.otf") format("truetype");
}
@font-face {
  font-family: GillSansBold;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: GillSansMT;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-MT.ttf") format("truetype");
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap|Titillium+Web:400,700');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

// Angular Material Theme
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// Libs imports always last
@import '~@fengbrasil/ngx-fengst-ui/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-auth/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-layout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-home/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-account/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-landing/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-exclusive-content/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-contact/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-partner/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-experience/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-checkout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-xray/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-events/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-embassies/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-gift/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-security/assets/dist/styles';
